<template>
  <div v-if="campaign" class="pb-10">
    <div class="row">
      <label class="form-control-label">
        <h4>Campaign Duration</h4>
      </label>
    </div>
    <div class="row">
      <label class="d-inline mx-10 form-control-label"> Start </label>
      <div class="d-inline mx-10">
        <DatePicker
          type="datetime"
          width-200px
          @confirm="update('start_date')"
          :confirm="true"
          :clearable="false"
          placeholder="n/a"
          ref="start_date"
          format="YYYY-MM-DD HH:mm:ss"
          value-type="format"
          :disabled-date="disabledBeforeToday"
          :disabled="disabled"
          v-model="campaign.start_date"
          :show-time-panel="showTimePanelStart"
          ><template v-slot:footer>
            <button
              class="mx-btn mx-btn-text"
              @click="showTimePanelStart = !showTimePanelStart"
            >
              {{ showTimePanelStart ? "select date" : "select time" }}
            </button>
          </template></DatePicker
        >
      </div>
      <label class="d-inline mx-10 form-control-label"> End </label>
      <div class="d-inline mx-10">
        <DatePicker
          type="datetime"
          width-200px
          @confirm="update('end_date')"
          @clear="update('end_date')"
          :confirm="true"
          placeholder="n/a"
          ref="end_date"
          format="YYYY-MM-DD HH:mm:ss"
          value-type="format"
          :disabled="disabled"
          v-model="campaign.end_date"
          :disabled-date="disabledBeforeStart"
          :show-time-panel="showTimePanelEnd"
          ><template v-slot:footer>
            <button
              class="mx-btn mx-btn-text"
              @click="showTimePanelEnd = !showTimePanelEnd"
            >
              {{ showTimePanelEnd ? "select date" : "select time" }}
            </button>
          </template></DatePicker
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { illicitBarcodeService } from "@/api/client";

export default {
  props: ["campaign"],

  components: {
    DatePicker,
  },

  data() {
    return {
      oldValue: null,
      start_date: null,
      end_date: null,
      showTimePanelStart: false,
      showTimePanelEnd: false,
    };
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  mounted() {
    this.oldValue = Object.assign({}, this.campaign);
  },

  methods: {
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },

    disabledBeforeStart(date) {
      const startDate = new Date(this.campaign.start_date);
      startDate.setHours(0, 0, 0, 0);
      return date < startDate;
    },

    async isFormValid(field) {
      switch (field) {
        case "start_date":
          if (moment(new Date()) > moment(this.campaign.start_date)) {
            await this.$dialogs.alert(
              "Start date ใส่ค่าได้ตั้งแต่วันเวลาที่ในอนาคตเท่านั้น"
            );
            this.$refs.start_date.focus();
            this.campaign.start_date = this.oldValue.start_date;
            return false;
          } else if (
            moment(this.campaign.start_date) > moment(this.campaign.end_date)
          ) {
            await this.$dialogs.alert(
              "กรุณาระบุวันเวลาเริ่มที่น้อยกว่าวันสิ้นสุด campaign"
            );
            this.$refs.start_date.focus();
            this.campaign.start_date = this.oldValue.start_date;
            return false;
          }

          return true;

        case "end_date":
          if (
            moment(this.campaign.start_date) > moment(this.campaign.end_date)
          ) {
            await this.$dialogs.alert(
              "กรุณาระบุวันเวลาเริ่มที่น้อยกว่าวันเวลาสิ้นสุด campaign"
            );
            this.$refs.end_date.focus();
            this.campaign.end_date = this.oldValue.end_date;
            return false;
          }

          return true;

        default:
          return true;
      }
    },

    async update(field) {
      this.showTimePanelEnd = false;
      this.showTimePanelStart = false;

      try {
        const formValid = await this.isFormValid(field);
        if (!formValid) return;
        const body = this.setBodyDateTime(field);
        await illicitBarcodeService.updateCampaign(this.campaign.id, body);
        this.oldValue = Object.assign({}, this.campaign);
        this.$emit("updated");
        this.$notify(`Campaign Duration updated.`);
      } catch (error) {
        this.$dialogs.alert(`Campaign Duration update error.`);
      }
    },
    setBodyDateTime(field) {
      switch (field) {
        case "end_date":
          return {
            end_date: this.campaign.end_date,
          };

        case "start_date":
          return {
            start_date: this.campaign.start_date,
          };

        default:
          return null;
      }
    },
  },
};
</script>

<style></style>
