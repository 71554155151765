<template>
  <div>
    <label class="form-control-label">
      <h4>Campaign Name</h4>
    </label>
    <input
      ref="name"
      type="text"
      v-model="name"
      maxlength="255"
      @change="update"
      :disabled="!canEdit"
      class="form-control"
    />
    <nameValidation :$v="$v" />
  </div>
</template>

<script>
import { illicitBarcodeService } from "@/api/client";
import nameValidation from "../validation/name.vue";
import { FormEditValidateRule as validateRules } from "../validation";

export default {
  props: ["campaign"],

  components: {
    nameValidation,
  },

  validations: { name: validateRules.name },

  data() {
    return {
      id: null,
      name: null,
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.campaign.status);
    },
  },

  mounted() {
    this.id = this.campaign.id;
    this.name = this.campaign.name;
  },

  methods: {
    async isFormValid() {
      if (this.$v.name.$invalid) {
        await this.$dialogs.alert("กรุณากรอก Campaign Name ให้ถูกต้อง");
        this.name = this.campaign.name;
        this.$refs.name.focus();
        return false;
      }
      return true;
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await illicitBarcodeService.updateCampaign(this.campaign.id, {
          name: this.name,
        });
        this.$notify(`Campaign name updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload Campaign name error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control-label {
  padding-left: 0px;
  h4 {
    margin-bottom: 0.5em;
  }
}
</style>
