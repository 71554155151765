<template>
  <div class="row">
    <div class="col-5">
      <label class="form-control-label">
        <h4>Welcome message</h4>
      </label>
      <textarea
        rows="4"
        maxlength="100"
        @change="update"
        :disabled="!canEdit"
        class="form-control"
        name="welcome_message"
        v-model="welcome_message"
      />
    </div>
    <div class="col-5">
      <label class="form-control-label">
        <h4>Thank you message</h4>
      </label>
      <textarea
        rows="4"
        maxlength="100"
        @change="update"
        :disabled="!canEdit"
        class="form-control"
        name="thank_you_message"
        v-model="thank_you_message"
      />
    </div>
  </div>
</template>

<script>
import { illicitBarcodeService } from "@/api/client";

export default {
  name: "Messages",

  props: ["campaign"],

  data() {
    return {
      welcome_message: null,
      thank_you_message: null,
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.campaign.status);
    },
  },

  mounted() {
    const { welcome_message, thank_you_message } = this.campaign;

    this.welcome_message = welcome_message;
    this.thank_you_message = thank_you_message;
  },

  methods: {
    async update(event) {
      const { name } = event.currentTarget;

      const form = {};
      form[name] = this[name];

      try {
        await illicitBarcodeService.updateCampaign(this.campaign.id, form);
        this.$notify(`Campaign updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Upload Campaign error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control-label {
  padding-left: 0px;
  h4 {
    margin-bottom: 0.5em;
  }
}
</style>
