<template>
  <div class="px-15" v-if="campaign">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary btn-round dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
        style="text-transform: uppercase"
      >
        {{ campaign.status }}
      </button>
      <div class="dropdown-menu" role="menu">
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('scheduled')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Publish
        </a>
        <a
          v-if="campaign.status == 'draft'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('archived')"
        >
          <i class="icon md-archive" aria-hidden="true"></i>Archive
        </a>
        <a
          v-if="campaign.status == 'scheduled'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-down" aria-hidden="true"> </i>
          Unpublished
        </a>

        <a
          v-if="campaign.status == 'ongoing'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="confirmAborted"
        >
          <i class="icon md-border-color" aria-hidden="true"></i>Abort
        </a>
        <a
          v-if="campaign.status == 'archived'"
          class="dropdown-item"
          href="javascript:void(0)"
          role="menuitem"
          @click="update('draft')"
        >
          <i class="icon md-long-arrow-up" aria-hidden="true"></i>Unarchive
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { illicitBarcodeService } from "@/api/client";
import { responseErrorMapping } from "../model/error";

export default {
  props: ["campaign"],
  methods: {
    validate(status) {
      const {
        name,
        end_date,
        start_date,
        point_reward,
        welcome_message,
        thank_you_message,
      } = this.campaign;

      switch (status) {
        case "scheduled":
          // Name
          if (!name) {
            this.$dialogs.alert("กรุณาระบุ Campaign name");
            return false;
          }

          if (name.length > 255) {
            this.$dialogs.alert(
              "Campaign name ต้องมีความยาวตัวอักษรไม่เกิน 255 ตัวอักษร"
            );
          }

          if (welcome_message.length > 100) {
            this.$dialogs.alert(
              "Welcome message ต้องมีความยาวตัวอักษรไม่เกิน 100 ตัวอักษร"
            );
          }

          if (thank_you_message.length > 100) {
            this.$dialogs.alert(
              "Thank you message ต้องมีความยาวตัวอักษรไม่เกิน 100 ตัวอักษร"
            );
          }

          if (!point_reward || +point_reward < 0 || +point_reward > 1000000) {
            this.$dialogs.alert(
              "Point reward ต้องมีค่าเป็นจำนวนเต็ม ตั้งแต่ 0 ขึ้นไป ถึง 1,000,000"
            );
          }

          // Date
          if (!start_date) {
            this.$dialogs.alert("กรุณากรอก Start date");
            return false;
          }

          if (!end_date) {
            this.$dialogs.alert("กรุณากรอก End date");
            return false;
          }

          if (new Date().getTime() > new Date(start_date).getTime()) {
            this.$dialogs.alert("Start date ต้องเป็นอนาคต.");
            return false;
          }

          //  End date >= Start
          if (moment(end_date) < moment(start_date)) {
            this.$dialogs.alert("End date ต้องมากกว่า Start date.");
            return false;
          }

          break;
      }

      return true;
    },

    async confirmAborted() {
      const r = confirm("คุณต้องการ Abort campaign นี้ใช่หรือไม่?");
      r ? this.update("aborted") : null;
    },

    async update(status) {
      try {
        if (!this.validate(status)) return;

        const { id } = this.campaign;
        const form = {
          status: status,
        };

        await illicitBarcodeService.updateCampaign(id, form);
        this.$emit("updated");
        this.$notify("Status updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },
  },
};
</script>

<style></style>
