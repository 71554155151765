var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question
    ? _c("div", { staticClass: "col-10 mr-10" }, [
        _c("h5", [_vm._v("No. " + _vm._s(_vm.index + 1))]),
        _c("table", { staticClass: "table table-borderless" }, [
          _c("tr", [
            _c("td", [_vm._v("Question")]),
            _c("td", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.question.question,
                    expression: "question.question",
                  },
                ],
                staticClass: "form-control",
                attrs: { rows: "3", disabled: _vm.disabled },
                domProps: { value: _vm.question.question },
                on: {
                  change: _vm.update,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.question, "question", $event.target.value)
                  },
                },
              }),
            ]),
            _c("td", [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button", disabled: _vm.disabled },
                  on: { click: _vm.removeQuestion },
                },
                [_c("i", { staticClass: "fa fa-trash" })]
              ),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("No. Choice")]),
            _c("td", [
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.question.no_choice,
                        expression: "question.no_choice",
                      },
                    ],
                    staticClass: "form-control w-100 mr-10",
                    attrs: { disabled: _vm.disabled },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.question,
                            "no_choice",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.update,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                    _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                    _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
                    _c("option", { attrs: { value: "fill" } }, [
                      _vm._v("Fill"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "checkbox-custom checkbox-primary checkbox-inline",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.question.multiple_answers,
                          expression: "question.multiple_answers",
                        },
                      ],
                      attrs: {
                        "true-value": 1,
                        "false-value": 0,
                        type: "checkbox",
                        disabled: _vm.disabled,
                        id: "question_" + _vm.question.id + "_multiple_answers",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.question.multiple_answers)
                          ? _vm._i(_vm.question.multiple_answers, null) > -1
                          : _vm._q(_vm.question.multiple_answers, 1),
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.question.multiple_answers,
                              $$el = $event.target,
                              $$c = $$el.checked ? 1 : 0
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.question,
                                    "multiple_answers",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.question,
                                    "multiple_answers",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.question, "multiple_answers", $$c)
                            }
                          },
                          _vm.update,
                        ],
                      },
                    }),
                    _c(
                      "label",
                      {
                        attrs: {
                          for:
                            "question_" + _vm.question.id + "_multiple_answers",
                        },
                      },
                      [_vm._v(" Multiple answers ")]
                    ),
                  ]
                ),
              ]),
            ]),
            _c("td"),
          ]),
          +_vm.question.no_choice >= 1
            ? _c("tr", [
                _c("td", [_vm._v("A")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.question.choice1,
                        expression: "question.choice1",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: _vm.disabled },
                    domProps: { value: _vm.question.choice1 },
                    on: {
                      change: _vm.update,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.question, "choice1", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td"),
              ])
            : _vm._e(),
          +_vm.question.no_choice >= 2
            ? _c("tr", [
                _c("td", [_vm._v("B")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.question.choice2,
                        expression: "question.choice2",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: _vm.disabled },
                    domProps: { value: _vm.question.choice2 },
                    on: {
                      change: _vm.update,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.question, "choice2", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td"),
              ])
            : _vm._e(),
          +_vm.question.no_choice >= 3
            ? _c("tr", [
                _c("td", [_vm._v("C")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.question.choice3,
                        expression: "question.choice3",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: _vm.disabled },
                    domProps: { value: _vm.question.choice3 },
                    on: {
                      change: _vm.update,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.question, "choice3", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td"),
              ])
            : _vm._e(),
          +_vm.question.no_choice >= 4
            ? _c("tr", [
                _c("td", [_vm._v("D")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.question.choice4,
                        expression: "question.choice4",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: _vm.disabled },
                    domProps: { value: _vm.question.choice4 },
                    on: {
                      change: _vm.update,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.question, "choice4", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td"),
              ])
            : _vm._e(),
          +_vm.question.no_choice >= 5
            ? _c("tr", [
                _c("td", [_vm._v("E")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.question.choice5,
                        expression: "question.choice5",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: _vm.disabled },
                    domProps: { value: _vm.question.choice5 },
                    on: {
                      change: _vm.update,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.question, "choice5", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("td"),
              ])
            : _vm._e(),
        ]),
        _c("hr"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }