<template>
  <div class="row">
    <div class="col-md-12">
      <label class="form-control-label">
        <h4>Questionnaire</h4>
      </label>

      <Question
        v-for="(question, index) in questions"
        :index="index"
        :key="question.id"
        :question="question"
        :campaign="campaign"
        @updated="$emit('updated')"
      />

      <div class="action">
        <button
          class="btn btn-default"
          :disabled="questions.length >= 20 || disabled"
          @click="addQuestion"
          type="button"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Question from "./Question.vue";
import { illicitBarcodeService } from "@/api/client";

const httpService = illicitBarcodeService.axiosInstance;

export default {
  name: "Questionnaire",

  props: ["campaign"],

  components: { Question },

  data() {
    return {
      questions: [],
    };
  },

  watch: {
    "campaign.questions"(n) {
      this.questions = n;
    },
  },

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  methods: {
    async addQuestion() {
      try {
        await httpService.post(`/campaign/${this.campaign.id}/question`);

        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Add question error.`);
      }
    },
  },

  mounted() {
    this.questions = this.campaign.questions;
  },
};
</script>

<style lang="scss" scoped>
.form-control-label {
  padding-left: 0px;
  h4 {
    margin-bottom: 0.5em;
  }
}
</style>
