<template>
  <div class="col-10 mr-10" v-if="question">
    <h5>No. {{ index + 1 }}</h5>
    <table class="table table-borderless">
      <tr>
        <td>Question</td>
        <td>
          <textarea
            rows="3"
            @change="update"
            class="form-control"
            :disabled="disabled"
            v-model="question.question"
          ></textarea>
        </td>
        <td>
          <button
            type="button"
            class="btn btn-default"
            :disabled="disabled"
            @click="removeQuestion"
          >
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </tr>
      <tr>
        <td>No. Choice</td>
        <td>
          <div class="d-flex">
            <select
              class="form-control w-100 mr-10"
              v-model="question.no_choice"
              @change="update"
              :disabled="disabled"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="fill">Fill</option>
            </select>

            <div class="checkbox-custom checkbox-primary checkbox-inline">
              <input
                :true-value="1"
                :false-value="0"
                type="checkbox"
                @change="update"
                :disabled="disabled"
                v-model="question.multiple_answers"
                :id="`question_${question.id}_multiple_answers`"
              />
              <label :for="`question_${question.id}_multiple_answers`">
                Multiple answers
              </label>
            </div>
          </div>
        </td>
        <td></td>
      </tr>

      <tr v-if="+question.no_choice >= 1">
        <td>A</td>
        <td>
          <input
            type="text"
            class="form-control"
            v-model="question.choice1"
            @change="update"
            :disabled="disabled"
          />
        </td>
        <td></td>
      </tr>

      <tr v-if="+question.no_choice >= 2">
        <td>B</td>
        <td>
          <input
            type="text"
            class="form-control"
            v-model="question.choice2"
            @change="update"
            :disabled="disabled"
          />
        </td>
        <td></td>
      </tr>

      <tr v-if="+question.no_choice >= 3">
        <td>C</td>
        <td>
          <input
            type="text"
            class="form-control"
            v-model="question.choice3"
            @change="update"
            :disabled="disabled"
          />
        </td>
        <td></td>
      </tr>

      <tr v-if="+question.no_choice >= 4">
        <td>D</td>
        <td>
          <input
            type="text"
            class="form-control"
            v-model="question.choice4"
            @change="update"
            :disabled="disabled"
          />
        </td>
        <td></td>
      </tr>

      <tr v-if="+question.no_choice >= 5">
        <td>E</td>
        <td>
          <input
            type="text"
            class="form-control"
            v-model="question.choice5"
            @change="update"
            :disabled="disabled"
          />
        </td>
        <td></td>
      </tr>
    </table>

    <hr />
  </div>
</template>

<script>
import { illicitBarcodeService } from "@/api/client";

const httpService = illicitBarcodeService.axiosInstance;

export default {
  props: ["index", "question", "campaign"],

  computed: {
    disabled() {
      return this.campaign.status != "draft";
    },
  },

  methods: {
    async update() {
      try {
        await httpService.put(
          `/campaign/${this.campaign.id}/question/${this.question.id}`,
          this.question
        );

        this.$emit("updated");
        this.$notify(`Question updated.`);
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update question error.`);
      }
    },

    async removeQuestion(id) {
      try {
        await httpService.delete(
          `/campaign/${this.campaign.id}/question/${this.question.id}`
        );

        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Remove question error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-primary {
  label {
    margin-left: 2em;
  }

  input[type="checkbox"]:checked + label {
    &::before {
      border-color: #2196f3;
      background-color: #2196f3;
    }
  }
}
</style>
