var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12" },
      [
        _vm._m(0),
        _vm._l(_vm.questions, function (question, index) {
          return _c("Question", {
            key: question.id,
            attrs: { index: index, question: question, campaign: _vm.campaign },
            on: {
              updated: function ($event) {
                return _vm.$emit("updated")
              },
            },
          })
        }),
        _c("div", { staticClass: "action" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: {
                disabled: _vm.questions.length >= 20 || _vm.disabled,
                type: "button",
              },
              on: { click: _vm.addQuestion },
            },
            [_c("i", { staticClass: "fa fa-plus" })]
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h4", [_vm._v("Questionnaire")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }