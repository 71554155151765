var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-5" }, [
      _vm._m(0),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.welcome_message,
            expression: "welcome_message",
          },
        ],
        staticClass: "form-control",
        attrs: {
          rows: "4",
          maxlength: "100",
          disabled: !_vm.canEdit,
          name: "welcome_message",
        },
        domProps: { value: _vm.welcome_message },
        on: {
          change: _vm.update,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.welcome_message = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { staticClass: "col-5" }, [
      _vm._m(1),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.thank_you_message,
            expression: "thank_you_message",
          },
        ],
        staticClass: "form-control",
        attrs: {
          rows: "4",
          maxlength: "100",
          disabled: !_vm.canEdit,
          name: "thank_you_message",
        },
        domProps: { value: _vm.thank_you_message },
        on: {
          change: _vm.update,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.thank_you_message = $event.target.value
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h4", [_vm._v("Welcome message")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "form-control-label" }, [
      _c("h4", [_vm._v("Thank you message")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }