<template>
  <div class="row">
    <div class="col-3">
      <label class="form-control-label">
        <h4>Point Reward</h4>
      </label>
      <input
        min="0"
        max="1000000"
        type="number"
        @change="update"
        ref="point_reward"
        :disabled="!canEdit"
        class="form-control"
        v-model="point_reward"
      />
    </div>
  </div>
</template>

<script>
import { illicitBarcodeService } from "@/api/client";

export default {
  name: "PointReward",

  props: ["campaign"],

  data() {
    return {
      point_reward: null,
    };
  },

  computed: {
    canEdit() {
      return ["draft"].includes(this.campaign.status);
    },
  },

  mounted() {
    this.point_reward = this.campaign.point_reward;
  },

  methods: {
    async isFormValid() {
      if (this.point_reward < 0) {
        await this.$dialogs.alert("กรุณาระบุ Point Reward มากกว่า 0");
        this.point_reward = this.campaign.point_reward;
        this.$refs.point_reward.focus();
        return false;
      }

      if (this.point_reward > 1000000) {
        await this.$dialogs.alert("กรุณาระบุ Point Reward น้อยกว่า 1,000,000");
        this.point_reward = this.campaign.point_reward;
        this.$refs.point_reward.focus();
        return false;
      }

      return true;
    },

    async update() {
      try {
        const formValid = await this.isFormValid();
        if (!formValid) return;

        await illicitBarcodeService.updateCampaign(this.campaign.id, {
          point_reward: this.point_reward,
        });
        this.$notify(`Point reward updated.`);
        this.$emit("updated");
      } catch (error) {
        error.response
          ? this.$dialogs.alert(error.response.data)
          : this.$dialogs.alert(`Update Point reward error.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control-label {
  padding-left: 0px;
  h4 {
    margin-bottom: 0.5em;
  }
}
</style>
