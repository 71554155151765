<template>
  <div class="page" v-if="campaign">
    <div class="page-main">
      <div class="page-header">
        <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
          <h1 class="page-title">Illicit Campaign</h1>
          <breadcrumb
            name="ILLICIT_BARCODE_CAMPAIGN_EDIT"
            :withLast.sync="campaign.name"
          ></breadcrumb>
          <div class="page-title page-header-actions">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="goBack"
            >
              <i class="icon md-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-10 offset-xxl-1">
            <div class="panel panel-bordered">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Campaign Information - {{ campaign.id }}
                </h3>
                <div class="panel-actions panel-actions-keep"></div>
              </div>
              <div class="panel-body">
                <form class="form-horizontal" v-if="campaign">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <CampaignName
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <StatusDropdown
                        :campaign="campaign"
                        @updated="fetchCampaign"
                      />
                    </div>
                  </div>
                  <hr />

                  <CampaignDuration
                    :campaign="campaign"
                    @updated="fetchCampaign"
                  />
                  <hr />

                  <Messages :campaign="campaign" @updated="fetchCampaign" />
                  <hr />

                  <PointReward :campaign="campaign" @updated="fetchCampaign" />
                  <hr />

                  <Questionnaire :campaign="campaign" @updated="fetchCampaign" />
                </form>
              </div>
              <div class="panel-footer">
                <div class="d-flex justify-content-between"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { illicitBarcodeService } from "@/api/client";

import Messages from "./components/Messages.vue";
import PointReward from "./components/PointReward.vue";
import CampaignName from "./components/CampaignName.vue";
import Questionnaire from "./components/Questionnaire.vue";
import StatusDropdown from "./components/StatusDropdown.vue";
import CampaignDuration from "./components/CampaignDuration.vue";

export default {
  name: "CampaignEdit",

  data() {
    return {
      campaign: null,
    };
  },

  components: {
    Messages,
    PointReward,
    CampaignName,
    Questionnaire,
    StatusDropdown,
    CampaignDuration,
  },

  computed: {},

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async fetchCampaign() {
      const id = this.$route.params.id;
      this.campaign = await illicitBarcodeService.getCampaignById(id);
    },
  },

  async created() {
    await this.fetchCampaign();
  },
};
</script>

<style lang="scss" scoped>
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 10px;
}
</style>
